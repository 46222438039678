import { type StripeElementsOptions } from '@stripe/stripe-js';

export const getStripeAmount = (amt: number) => Math.round(amt * 100);

export const getDollarsFromStripeAmount = (amount: number) => {
  const dollars = amount / 100;
  return Number(dollars.toFixed(2));
};

export const STRIPE_ELEMENT_OPTIONS: Pick<
  StripeElementsOptions,
  'appearance' | 'loader'
> = {
  appearance: {
    theme: 'flat',
    rules: {
      '.Input': {
        borderRadius: '1.2rem',
        backgroundColor: '#F4F4F4',
        fontSize: '1.3rem',
      },
      '.Input:focus': {
        outline: '2px solid black',
        boxShadow: 'none',
        backgroundColor: '#FFF',
      },
    },
    variables: {
      colorPrimary: '#000000',
      // colorPrimary: '#C41719',
      fontSizeBase: '0.8rem',
    },
  },
  loader: 'always',
};
