import { OrderDocument } from '@models/order';
import { FirebaseCallable, functions, httpsCallable } from '@util/firebase';
import { logError } from '@util/logError';
import { LabelResponse } from '../labels/labels.service';
import { addNewNote, markAsDelivered } from '../orders/orders.service';
import {
  getEstimatesArgs,
  getLabelArgs,
  getRatesArgs,
  Rate,
  RateResponse,
  TrackPackageResponse,
} from './shipengine.types';

export async function getEstimates(args: getEstimatesArgs): Promise<Rate[]> {
  try {
    const { data } = await httpsCallable<getEstimatesArgs, Rate[]>(
      functions,
      FirebaseCallable.getEstimates
    )(args);
    return data;
  } catch (error) {
    logError(error);
    return [];
  }
}

export async function trackPackage(
  carrier: string,
  trackingNo: string,
  service_code?: string
): Promise<TrackPackageResponse> {
  const { data } = await httpsCallable<
    { carrier: string; trackingNo: string; service_code?: string },
    TrackPackageResponse
  >(
    functions,
    FirebaseCallable.trackPackage
  )({ carrier, trackingNo, service_code });
  return data;
}

export async function trackPackageGlobal(
  trackingNo: string
): Promise<TrackPackageResponse> {
  const { data } = await httpsCallable<
    { trackingNo: string },
    TrackPackageResponse
  >(
    functions,
    FirebaseCallable.trackPackageGlobal
  )({ trackingNo });
  return data;
}

export async function getRates(args: getRatesArgs): Promise<Rate[]> {
  if (args.shipment?.package.insured_value?.amount === 0) {
    delete args.shipment.package.insured_value;
  }
  const { data } = await httpsCallable<getRatesArgs, RateResponse>(
    functions,
    FirebaseCallable.getRates
  )(args);

  if (data?.rate_response?.errors && !data?.rate_response?.rates?.length)
    throw new Error(
      data.rate_response.errors[0]?.message ??
        'Shipping is not available for this address. Please contact the seller for assistance.'
    );
  return data?.rate_response?.rates ?? [];
}

export async function fixLabels(orderId: string) {
  const { data } = await httpsCallable<
    { orderId: string },
    { updated: number; errors: string[] }
  >(
    functions,
    FirebaseCallable.fixLabel
  )({ orderId });
  return data;
}

export async function getCheapestEstimate(
  product_id: string,
  to_postal_code: string,
  to_country_code = 'US'
) {
  const rates = await getEstimates({
    product_id,
    to_postal_code,
    to_country_code,
  });

  let cheapest: number | null = null;
  rates.forEach((rate) => {
    cheapest = cheapest
      ? Math.min(cheapest, rate.total_amount)
      : rate.total_amount;
  });
  if (cheapest === null) {
    throw new Error(
      'Shipping is not available for this postal code and country'
    );
  }
  return cheapest;
}

export const checkDelivery = async (
  trackingResponse: TrackPackageResponse,
  order: OrderDocument,
  itemState: number,
  isAdmin: boolean
) => {
  if (!trackingResponse || !order.sellers) return;
  try {
    if (
      trackingResponse.status_code === 'DE' &&
      itemState <= 1 &&
      trackingResponse.tracking_number
    ) {
      const orderPostal = order.address?.postal_code
        ?.split('-')?.[0]
        ?.replace(/\s+/g, '')
        ?.toUpperCase();
      const trackingPostal = trackingResponse.events?.[0]?.postal_code
        ?.split('-')?.[0]
        ?.replace(/\s+/g, '')
        ?.toUpperCase();

      if (orderPostal && trackingPostal && orderPostal === trackingPostal) {
        let foundTracking = false;
        Object.keys(order.sellers).find((sellerId) => {
          order.sellers![sellerId].forEach((item) => {
            if (item.tracking_number)
              item.tracking_number = item.tracking_number.toUpperCase();
            if (
              item.tracking_number ===
              trackingResponse.tracking_number.toUpperCase()
            ) {
              const milliToHour = 2.7777777777778e-7;
              const delivery_time =
                new Date(
                  trackingResponse.actual_delivery_date || 0
                ).valueOf() || Date.now();
              if ((delivery_time - order.created) * milliToHour > 12) {
                item.state = item.state === 1 ? 2 : item.state;
                item.delivery_time = delivery_time;
                foundTracking = true;
              } else {
                if (isAdmin)
                  alert(
                    `Delivery Time (${delivery_time}) isn't 12hrs after Order Creation (${order.created})`
                  );
                return;
              }
            }
          });
          return foundTracking;
        });
        if (foundTracking) {
          await markAsDelivered(order);
          if (isAdmin) {
            await addNewNote(
              order.id,
              'Delivery Confirmed from Modal',
              process.env.NEXT_PUBLIC_SUPPORT_ID
            );
            alert('Order status updated to delivered');
          }
        }
      } else if (isAdmin)
        alert(
          `Order Postal (${orderPostal}) doesn't match Tracking Postal (${trackingPostal})`
        );
    }
  } catch (e) {
    logError(e);
    if (isAdmin) alert('Error updating order, check console');
  }
};

export const purchaseLabel = async (args: getLabelArgs) => {
  return await httpsCallable<
    getLabelArgs,
    LabelResponse & { rate_id: string; charged: number }
  >(
    functions,
    FirebaseCallable.getLabel
  )(args);
};

// if label_id is unavailable, use tracking_number
export async function voidLabel(label_id: string, tn?: string) {
  const { data } = await httpsCallable<
    { label_id: string; tn?: string },
    { approved: boolean; message: string }
  >(
    functions,
    FirebaseCallable.voidLabel
  )({ label_id, ...(tn && { tn }) });
  return data;
}

export async function printReturnLabel(label_id: string) {
  const { data } = await httpsCallable<{ label_id: string }, LabelResponse>(
    functions,
    FirebaseCallable.getReturnLabel
  )({ label_id });
  return data;
}
