import Stepper from '@ui/Stepper';
import { AnimatePresence } from 'framer-motion';
import { MotionDiv } from 'motion';
import { createRef, useEffect, useRef } from 'react';
export interface ProcessStep<T extends string = string> {
  id: T;
  icon?: React.ReactNode;
  content: React.ReactNode;
  title?: string;
  validateStep?: () => Boolean;
}
interface BaseProcessProps {
  steps: ProcessStep[];
  currentStep: number;
  noStepper?: boolean;
  overflowStyle?: 'hidden' | 'visible';
}

export function BProcess({
  steps,
  currentStep,
  overflowStyle = 'hidden',
}: BaseProcessProps) {
  const stepObj = steps[currentStep] as undefined | ProcessStep<any>;
  return (
    <div className={`w-full overflow-${overflowStyle} h-full`}>
      <AnimatePresence>
        <MotionDiv
          key={stepObj?.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          className="h-full"
        >
          {stepObj?.content}
        </MotionDiv>
      </AnimatePresence>
    </div>
  );
}

const BaseProcess = ({ steps, currentStep, noStepper }: BaseProcessProps) => {
  useEffect(() => {
    if (container?.current) {
      container.current.scrollLeft =
        container.current.offsetWidth * currentStep;
    }
    setTimeout(() => {
      // Make any step refs that are not in view hidden
      stepRefs.current.forEach((ref, index) => {
        if (ref.current) {
          ref.current.style.visibility =
            index === currentStep ? 'visible' : 'hidden';
        }
      });
    }, 250);
  }, [currentStep]);

  useEffect(() => {
    // handle resize
    const handleResize = () => {
      if (container?.current) {
        container.current.style.scrollBehavior = 'auto';
        container.current.scrollLeft =
          container.current.offsetWidth * currentStep;
        setTimeout(() => {
          if (container?.current) {
            container.current.style.scrollBehavior = 'smooth';
          }
        });
      }
    };
    window.addEventListener('resize', handleResize);
  });

  // create refs for each step
  const stepRefs = useRef(
    steps.map(() => createRef() as React.RefObject<HTMLDivElement>)
  );

  const container = useRef<HTMLDivElement>(null);
  return (
    <>
      {!noStepper && <Stepper steps={steps} currentStep={currentStep} />}
      <div
        className="scroll-smooth-no-safari flex w-full flex-nowrap overflow-x-hidden"
        ref={container}
      >
        {steps.map((step, index) => (
          <div
            className={`h-full w-full flex-custom transition-opacity duration-500 ${
              currentStep === index ? 'opacity-100' : 'opacity-0'
            }`}
            key={step.id}
            ref={stepRefs.current[index]}
          >
            {step.content}
          </div>
        ))}
      </div>
    </>
  );
};

export default BaseProcess;
