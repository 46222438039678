import { AddressElement, useElements } from '@stripe/react-stripe-js';
import {
  StripeAddressElement,
  StripeAddressElementChangeEvent,
} from '@stripe/stripe-js';
import { ShipEngineAddress } from '@util/firestore/shipengine';
import { stripeAddressToAddressDocument } from '@util/firestore/stripe';
import { validateAddresses } from '@util/firestore/users';
import { debounce } from '@util/index';
import { AddressDocument, addressSchema } from '@models/address';
import { useAuth } from 'context/AuthContext';
import React from 'react';
import FormLabel from './controls/FormLabel';

export type StripeAddressChangeEvent =
  | {
      status: 'valid';
      result: AddressDocument;
      isNewAddress: boolean;
    }
  | {
      status: 'invalid';
      matched_address: ShipEngineAddress;
      original_address: AddressDocument;
      error?: string;
    }
  | {
      status: 'incomplete';
      result: StripeAddressElementChangeEvent;
    }
  | {
      status: 'validating';
    };

type StripeAddressFormProps = {
  onChange?: (e: StripeAddressChangeEvent) => void;
  onReady?: (e: StripeAddressElement) => void;
  mode: 'shipping' | 'billing';
  addresses?: AddressDocument[];
  displayNameOption?: 'split' | 'full' | 'organization';
  defaultValues?: AddressDocument;
  elementKey?: string | number;
  skipValidation?: boolean;
};

const StripeAddressForm = ({
  onChange,
  onReady,
  mode,
  addresses,
  displayNameOption = 'full',
  defaultValues,
  // elementKey,
  skipValidation,
}: StripeAddressFormProps) => {
  const [error, setError] = React.useState('');
  const contacts = addresses?.map((a) => ({
    name: a.name ?? '',
    phone: a.phone ?? '',
    address: {
      line1: a.address_line1,
      line2: a.address_line2 ?? undefined,
      city: a.city_locality,
      state: a.state_province ?? '',
      postal_code: a.postal_code ?? '',
      country: a.country_code,
    },
  }));
  const defaults = defaultValues && {
    name: defaultValues.name,
    phone: defaultValues.phone ?? '',
    address: {
      line1: defaultValues.address_line1,
      line2: defaultValues.address_line2,
      city: defaultValues.city_locality,
      state: defaultValues.state_province ?? '',
      postal_code: defaultValues.postal_code ?? '',
      country: defaultValues.country_code,
    },
  };
  const { userDoc } = useAuth();

  const handleChange = async (e: StripeAddressElementChangeEvent) => {
    if (e.complete) {
      const address = stripeAddressToAddressDocument(e);
      if (address.name.trim().length < 2) {
        setError('Please enter a valid name');
        return;
      }
      onChange?.({
        status: 'validating',
      });
      const res = await validateAddresses([address]);
      if (res[0].status === 'verified' || res[0].status === 'warning') {
        const addressDoc = addressSchema.parse(res[0].matched_address);
        onChange?.({
          status: 'valid',
          result: addressDoc,
          isNewAddress: e.isNewAddress,
        });
        setError('');
      } else if (res[0].status === 'error' || res[0].status === 'unverified') {
        if (skipValidation) {
          onChange?.({
            status: 'valid',
            result: address,
            isNewAddress: e.isNewAddress,
          });
          setError(
            `We were unable to verify this address. If you are sure it's correct, please continue.`
          );
          return;
        }
        setError(
          res[0]?.messages[0]?.message ??
            'Unable to verify address. Please contact support.'
        );
        onChange?.({
          status: 'invalid',
          matched_address: res[0].matched_address,
          original_address: address,
          error: res[0]?.messages[0]?.message,
        });
      }
    } else {
      onChange?.({
        status: 'incomplete',
        result: e,
      });
    }
  };
  const debouncedHandleChange = debounce(handleChange, 500);
  const elements = useElements();
  elements?.getElement('address')?.update({});
  if (!userDoc) return null;

  return (
    <>
      {error && (
        <div className=" mb-4 flex justify-center rounded-2xl bg-red-200 py-[1.6rem]">
          <span className="p-4 font-semibold text-red-500">{error}</span>
        </div>
      )}
      <AddressElement
        options={{
          mode: mode,
          blockPoBox: !userDoc.roles?.admin, // block for non-admin users
          display: { name: displayNameOption },
          autocomplete: {
            mode: 'google_maps_api',
            apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
          },
          fields: {
            phone: 'always',
          },
          contacts,
          defaultValues: defaults
            ? defaults
            : {
                phone: contacts?.[0]?.phone,
              },
          validation: { phone: { required: 'always' } },
        }}
        onChange={debouncedHandleChange}
        onReady={onReady}
      />
    </>
  );
};

export default StripeAddressForm;
